var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-card",
        {
          staticClass: "mx-auto",
          class: !_vm.renderInCourseDesign
            ? "project-card"
            : "pa-1 rounded-xl border-1-2 border-solid border-primary",
          attrs: { "max-width": "550" },
        },
        [
          _c(
            "div",
            { staticClass: "pa-0 ma-0" },
            [
              _c(
                "v-row",
                { staticClass: "pa-0 project-title-grade-dropdown-section" },
                [
                  _c(
                    "v-col",
                    { staticClass: "pa-0 ma-0", attrs: { cols: "7" } },
                    [
                      _c(
                        "v-card-title",
                        {
                          staticClass:
                            "my-0 py-0 ml-0 pl-0 mk-project-title custom-font-family text-truncate",
                        },
                        [_vm._v(" " + _vm._s(_vm.project.title) + " ")]
                      ),
                      _vm.project.upload_date
                        ? _c(
                            "v-card-subtitle",
                            {
                              staticClass:
                                "pt-1 mt-1 ml-0 pl-0 mk-project-upload-date custom-font-family",
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    !_vm.$language
                                      ? "Upload Date"
                                      : "প্রকাশের তারিখ"
                                  ) +
                                  " : " +
                                  _vm._s(
                                    !_vm.$language
                                      ? _vm.project.upload_date
                                      : new Date(
                                          _vm.project.upload_date
                                        ).toLocaleString("bn-BD", {
                                          dateStyle: "medium",
                                        })
                                  ) +
                                  " "
                              ),
                            ]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  !_vm.renderInCourseDesign
                    ? _c(
                        "v-col",
                        {
                          staticClass: "pa-0 ma-0 d-flex justify-end",
                          attrs: { cols: "4" },
                        },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass:
                                "mk-project-btn-grade custom-font-family",
                              staticStyle: { "margin-right": "1rem" },
                              attrs: {
                                depressed: "",
                                plain: "",
                                to: `projectGrades/${_vm.project.object_id}`,
                              },
                            },
                            [
                              _c("img", {
                                attrs: {
                                  src: require("../assets/Grade.svg"),
                                  width: "25",
                                  height: "26",
                                  alt: "",
                                },
                              }),
                              _vm._v(
                                " " +
                                  _vm._s(!_vm.$language ? "Grade" : "গ্রেড") +
                                  " "
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  !_vm.renderInCourseDesign
                    ? _c(
                        "v-col",
                        {
                          staticClass: "pa-0 ma-0 project-dropdown-section",
                          attrs: { cols: "1" },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "text-center d" },
                            [
                              _c(
                                "v-menu",
                                {
                                  attrs: { "offset-y": "", left: "" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function ({ on, attrs }) {
                                          return [
                                            _vm.isOwner
                                              ? _c(
                                                  "v-btn",
                                                  _vm._g(
                                                    _vm._b(
                                                      {
                                                        attrs: {
                                                          color: "black",
                                                          icon: "",
                                                        },
                                                      },
                                                      "v-btn",
                                                      attrs,
                                                      false
                                                    ),
                                                    on
                                                  ),
                                                  [
                                                    _c("v-icon", [
                                                      _vm._v(
                                                        "mdi-dots-vertical"
                                                      ),
                                                    ]),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    152086974
                                  ),
                                },
                                [
                                  _c(
                                    "v-list",
                                    [
                                      _c(
                                        "v-list-item",
                                        {
                                          staticClass:
                                            "project-card-menu-list-item clickable",
                                          on: {
                                            click: function ($event) {
                                              return _vm.$emit(
                                                "delete",
                                                _vm.project
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "v-list-item-title",
                                            {
                                              staticClass:
                                                "subtitle-2 custom-font-family",
                                            },
                                            [
                                              _c("v-icon", [
                                                _vm._v("fas fa-list"),
                                              ]),
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    !_vm.$language
                                                      ? "Delete Project"
                                                      : "প্রজেক্ট ডিলিট করুন"
                                                  )
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              ),
              !_vm.renderInCourseDesign
                ? _c(
                    "v-row",
                    {
                      staticClass:
                        "px-0 mb-0 py-0 mk-project-batches-total-mark-section",
                    },
                    [
                      _c(
                        "v-col",
                        {
                          staticClass:
                            "pa-0 ma-0 mk-project-share-with-batches",
                          attrs: { cols: "6" },
                        },
                        [
                          !_vm.$language
                            ? _c(
                                "v-card-subtitle",
                                { staticClass: "custom-font-family pa-0 ma-0" },
                                [
                                  _c("img", {
                                    staticClass: "mr-2",
                                    attrs: {
                                      src: require("../assets/Share_N_Batch.svg"),
                                      alt: "",
                                      height: "31",
                                      width: "31",
                                    },
                                  }),
                                  _vm._v(
                                    " With " +
                                      _vm._s(_vm.share_batches_count) +
                                      " Batches "
                                  ),
                                ]
                              )
                            : _c(
                                "v-card-subtitle",
                                { staticClass: "custom-font-family pa-0 ma-0" },
                                [
                                  _c("img", {
                                    staticClass: "mr-2",
                                    attrs: {
                                      src: require("../assets/Share_N_Batch.svg"),
                                      alt: "",
                                      height: "31",
                                      width: "31",
                                    },
                                  }),
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.share_batches_count) +
                                      " টি ব্যাচের সাথে শেয়ার করা হয়েছে "
                                  ),
                                ]
                              ),
                        ],
                        1
                      ),
                      _c("v-col", {
                        staticClass: "pa-0 ma-0 mk-project-total-mark",
                        attrs: { cols: "5" },
                      }),
                      _c("v-col", {
                        staticClass: "pa-0 ma-0",
                        attrs: { cols: "4" },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "v-row",
            { staticClass: "pa-0 mk-share-detail-section" },
            [
              _c(
                "v-col",
                {
                  staticClass: "d-flex justify-left ma-0 pa-0 mk-detail",
                  attrs: { cols: "5" },
                },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "custom-font-family",
                      attrs: {
                        depressed: "",
                        tile: "",
                        plain: "",
                        to: `projects/${_vm.project.object_id}`,
                      },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(!_vm.$language ? "Details" : "বিস্তারিত") +
                          " "
                      ),
                    ]
                  ),
                ],
                1
              ),
              !_vm.renderInCourseDesign
                ? _c(
                    "v-col",
                    {
                      staticClass: "d-flex ma-0 pa-0 mk-share",
                      attrs: { cols: "7" },
                    },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "custom-font-family",
                          attrs: { depressed: "", tile: "", plain: "" },
                          on: {
                            click: function ($event) {
                              return _vm.$emit("share")
                            },
                          },
                        },
                        [
                          _c("img", {
                            staticClass: "mr-2",
                            attrs: {
                              src: require("../assets/Share_Btn.svg"),
                              width: "25",
                              height: "25",
                              alt: "",
                            },
                          }),
                          _vm._v(
                            " " +
                              _vm._s(!_vm.$language ? "Share" : "শেয়ার করুন") +
                              " "
                          ),
                        ]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { width: "100vw" },
          model: {
            value: _vm.isFilePreviewerVisible,
            callback: function ($$v) {
              _vm.isFilePreviewerVisible = $$v
            },
            expression: "isFilePreviewerVisible",
          },
        },
        [
          _vm.isFilePreviewerVisible
            ? _c("file-previewer", {
                attrs: {
                  "preview-url": _vm.previewUrl,
                  "download-url": _vm.downloadUrl,
                  "is-video": _vm.project.is_video
                    ? _vm.project.is_video
                    : false,
                  title: _vm.project.title,
                },
                on: {
                  cancel: function ($event) {
                    _vm.isFilePreviewerVisible = false
                  },
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }